// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { AreaProvider } from '../AreaContext/AreaContext'
import { mediaQuery, mediaOnly } from '../../styles/media'

import Ribbon from '../../components/Ribbon/Ribbon'

type Props = {
  children?: React.Node,
  hasHero?: boolean,
  hasPrint?: boolean,
  printText?: string,
  wizard?: React.Node,
  breadcrumbs?: React.Node,
  date?: React.Node,
  printButton?: React.Node,
}

const ArticleEl = styled.article`
  position: relative;

  border-radius: ${({ theme }) =>
    theme.borderRadius ? `${rem(theme.borderRadius)}` : rem(8)};

  margin: 0 auto;
  margin-bottom: ${({ hasWizard }) => !hasWizard && rem(80)};

  ${mediaQuery.lg`
    margin-bottom: 0;
  `};

  ${mediaOnly.xs`
    border-radius: 0;
    margin-left: -${rem(30)};
    margin-right: -${rem(30)};
  `}

  & > :last-child {
    margin-bottom: 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  padding-top: ${rem(96)};
  margin-bottom: ${rem(48)};
  z-index: 1; /* In order to stay on top of Hero */
  transition: margin-top .3s ease-in-out;

  ${mediaOnly.xs`
    padding-top: 0;
    margin-bottom: ${rem(64)};
  `}

  ${mediaQuery.sm`
    ${({ hasHero }) => (hasHero ? 'margin-top: -160px;' : null)}
  `}

  ${mediaQuery.lg`
    ${({ hasHero }) => (hasHero ? 'margin-top: -182px;' : null)}
  `}
`

const BackgroundMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 40vh;
  background: ${({ theme }) => theme.articleBackground};

  ${mediaOnly.xs`
    display: none;
  `}

  @media print {
    display: none;
  }
`

const WizardWrapper = styled.div`
  margin-top: ${rem(40)};
`

const Library = ({
  children,
  hasHero,
  hasPrint,
  printText,
  wizard,
  date,
  breadcrumbs,
  printButton,
  ...rest
}: Props) => {
  const hasWizard = !!wizard

  return (
    <Ribbon xsMargin={0} clearChildMargin={false}>
      <AreaProvider value="library">
        <ArticleEl {...rest} hasWizard={hasWizard}>
          {printButton}
          {breadcrumbs}
          {date}
          {children}
        </ArticleEl>
        {hasWizard && <WizardWrapper>{wizard}</WizardWrapper>}
      </AreaProvider>
    </Ribbon>
  )
}

export default Library
