// @flow
import * as React from 'react'
import DocumentTitle from 'react-document-title'
import { ThemeProvider } from 'styled-components'

import { themes, DEFAULT_THEME } from '../styles/theme'
import { splitModules } from '../modules/modules-utils'

import type { ThemeViewModel } from '../types/ThemeViewModel'
import type { ThemeTypes } from '../types/enums/ThemeTypes'
import type { ModuleInstance } from '../modules/modules-utils'
import type { HtmlConfigViewModel } from '../types/HtmlConfigViewModel'
import type { MetaViewModel } from '../types/MetaViewModel'

import ErrorBoundary from './ErrorBoundary'
import Main from '../components/Main/Main'
import Article from '../components/Article/Article'
import Library from '../components/Library/Library'
import PreviewBadge from '../components/PreviewBadge/PreviewBadge'
import PageFocus from './PageFocus'
import Narrow from '../components/Narrow/Narrow'
import NotificationsProvider from '../components/NotificationsContext/NotificationsContext'
import ToastContainer from '../components/ToastContainer/ToastContainer'

export const ConfigContext: React.Context<{
  imgixDomain?: string,
  googleTagManagerId?: string,
  meta: MetaViewModel,
  theme?: string,
}> = React.createContext({ meta: {} })

type Props = HtmlConfigViewModel & {
  modules: Array<ModuleInstance>,
  meta: MetaViewModel,
  pathname: string,
  isLoading: boolean,
}

type State = {}

class AppShell extends React.PureComponent<Props, State> {
  static displayName = 'AppShell'

  static renderModule(
    module: ?ModuleInstance,
    id?: string,
    debug: boolean = false,
  ) {
    if (!module || !module.module) return null
    return React.createElement(
      ErrorBoundary,
      { debug, module: module.name, key: id },
      React.createElement(module.module, module.props),
    )
  }

  getCurrentTheme = (themeName?: ThemeTypes): ThemeViewModel => {
    if (!themeName) return themes[DEFAULT_THEME]

    return themes[themeName] || themes[DEFAULT_THEME]
  }

  render() {
    const {
      meta,
      modules,
      pathname,
      debug,
      previewLink,
      theme,
      imgixDomain,
      googleTagManagerId,
    } = this.props
    const split = splitModules(modules)
    const hasHero = modules.some(item => {
      if (item.name === 'Hero') {
        const { image, video } = item.props
        const hasMedia = (image && image.src) || (video && video.src)
        return hasMedia
      } else {
        return false
      }
    })

    const activeTheme = this.getCurrentTheme(theme)

    return (
      <ConfigContext.Provider
        value={{ imgixDomain, googleTagManagerId, meta, theme }}
      >
        <ThemeProvider theme={activeTheme}>
          <NotificationsProvider>
            <ToastContainer />
            <>
              {meta.title && (
                <DocumentTitle title={meta.title || meta.siteName} />
              )}
              {AppShell.renderModule(
                split.cookieDisclaimerModule,
                'cookieDisclaimer',
                debug,
              )}
              {AppShell.renderModule(split.cookieBanner, 'cookie', debug)}
              {AppShell.renderModule(split.header, 'header', debug)}
              <Main>
                {split.preModules.map((module, index) =>
                  AppShell.renderModule(
                    module,
                    `${pathname}_pre_${index}`,
                    debug,
                  ),
                )}

                {/* Render article area content */}
                {split.article.length ? (
                  <Article
                    hasHero={hasHero}
                    printButton={AppShell.renderModule(
                      split.printButton,
                      'printButton',
                      debug,
                    )}
                    breadcrumbs={
                      theme !== 'kids' || theme !== 'lgbt'
                        ? AppShell.renderModule(
                            split.breadcrumbs,
                            'breadcrumbs',
                            debug,
                          )
                        : null
                    }
                    date={AppShell.renderModule(
                      split.date,
                      'displayDate',
                      debug,
                    )}
                    wizard={AppShell.renderModule(
                      split.wizard,
                      'wizard',
                      debug,
                    )}
                  >
                    {split.article.map((module, index) =>
                      AppShell.renderModule(
                        module,
                        `${pathname}_main_${index}`,
                        debug,
                      ),
                    )}
                  </Article>
                ) : null}

                {/* Render library area content */}
                {split.library.length ? (
                  <Library
                    hasHero={hasHero}
                    date={AppShell.renderModule(
                      split.date,
                      'displayDate',
                      debug,
                    )}
                    wizard={AppShell.renderModule(
                      split.wizard,
                      'wizard',
                      debug,
                    )}
                  >
                    {split.library.map((module, index) =>
                      AppShell.renderModule(
                        module,
                        `${pathname}_main_${index}`,
                        debug,
                      ),
                    )}
                  </Library>
                ) : null}

                {/* Render narrow content */}
                {split.narrow.length ? (
                  <Narrow
                    sidebar={split.sidebar.map((module, index) =>
                      AppShell.renderModule(
                        module,
                        `${pathname}_sidebar_${index}`,
                        debug,
                      ),
                    )}
                    anchorItems={split.narrow
                      .filter(
                        module =>
                          module.name === 'Accordion' ||
                          module.name === 'ActionTextModule',
                      )
                      .map(module => module.props)}
                  >
                    {/* Render narrow area content */}
                    {split.narrow.map((module, index) =>
                      AppShell.renderModule(
                        module,
                        `${pathname}_main_${index}`,
                        debug,
                      ),
                    )}
                  </Narrow>
                ) : null}

                {/* Render wide content */}
                {split.modules.map((module, index) =>
                  AppShell.renderModule(module, `${pathname}_${index}`, debug),
                )}
              </Main>
              {AppShell.renderModule(split.footer, 'footer', debug)}
              {previewLink ? (
                <PreviewBadge
                  currentUrl={meta.absoluteUrl}
                  link={previewLink}
                />
              ) : null}
              <PageFocus modules={modules} />
            </>
          </NotificationsProvider>
        </ThemeProvider>
      </ConfigContext.Provider>
    )
  }
}

export default AppShell
