// @flow
import * as React from 'react'
import Heading from '../Heading/Heading'
import Grid from '../Grid/Grid'
import styled from 'styled-components'
import Button from '../Button/Button'
import Paragraph from '../Paragraph/Paragraph'

type Props = {
  title?: string,
  description?: string,
  name: string,
  outline: boolean,
  debug?: boolean,
  details: Array<{
    name: string,
    value: any,
  }>,
  handleReload?: () => void,
}

const ErrorCard = styled.div`
  outline: ${p => p.outline && '2px red dashed'};

  && {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`

const Summary = styled.summary`
  font-weight: 700;
  margin-bottom: 0.5rem;
`

const Stack = styled.pre`
  background: rgba(0, 0, 0, 0.6);
  color: rgb(232, 232, 232);
  line-height: 1.4;
  white-space: pre;
  font-family: Menlo, Consolas, monospace;
  font-size: 14px;
  padding: 15px;
  margin: 0;
  overflow-x: scroll;
  margin-bottom: 0.5rem;
`

const Divider = styled.hr`
  margin: 1rem 0;
`

const serializeProps = props =>
  JSON.stringify(
    {
      ...props,
      children: undefined,
    },
    2,
  )
    .replace(/\\u002F/g, '/')
    .replace(/\\u003C/g, '<')
    .replace(/\\u003E/g, '>')

const StyledParagraph = styled(Paragraph)`
  font-size: ${({ theme }) => theme.bodyFontSize + 'px'};
  color: ${({ theme }) => theme.text};
  line-height: 2;
`

/**
 * Renders a ErrorMessage to the DOM when a component fails to render.
 */
const FriendlyError = ({
  title,
  description,
  details,
  debug,
  outline,
  handleReload,
}: Props) => (
  <Grid width="sm">
    <ErrorCard outline={outline}>
      <Heading level={2}>{title || 'Der skete en fejl'}</Heading>
      <StyledParagraph marginBottom={!!handleReload}>
        {description || 'Prøv at genindlæse siden'}
      </StyledParagraph>
      {handleReload && (
        <Button type="secondary" onClick={handleReload}>
          {/* todo: don't use hardcoded value */}
          Genindlæs
        </Button>
      )}
      {debug ? <Divider /> : null}
      {details &&
        debug &&
        details.map(
          ({ name, value }) =>
            value && (
              <details key={name}>
                <Summary>{name}</Summary>
                <Stack>
                  <code>
                    {typeof value === 'string' ? value : serializeProps(value)}
                  </code>
                </Stack>
              </details>
            ),
        )}
    </ErrorCard>
  </Grid>
)

FriendlyError.defaultProps = {
  outline: true,
}

export default FriendlyError
