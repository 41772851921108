// @flow
import * as React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { rem } from 'polished'
import { durations } from '../../styles/animations'
import { focusOutline, getFontSize } from '../../styles/style-helpers'

import type { LinkViewModel } from '../../types/LinkViewModel'
import type { ButtonTypes } from '../../types/enums/ButtonTypes'
import type { AreaTypes } from '../../types/enums/AreaTypes'
import BaseButton from './BaseButton'

type Props = LinkViewModel & {
  children?: React.Node,
  buttonType?: string,
  /** Always render as anchor tag, don't fall back to button */
  isAnchor?: boolean,
  disabled?: boolean,
  icon?: React.Node,
  iconRightSide?: boolean,
  type: ButtonTypes, // todo: this prop should not be called type, it is a native html property!
  negative?: boolean,
  /** Fill the container width */
  fill?: boolean,
  fluid?: boolean,
  /** To indicate a loading state visually */
  loading?: boolean,
  area?: AreaTypes,
}

/**
 * Create variations of the Button based on the Button Class
 **/
const Base = styled(BaseButton)`
  && {
    min-width: ${rem(110)};
    text-align: center;
    font-size: ${({ theme }) =>
      theme.name === 'kids' || theme.name === 'lgbt'
        ? rem(theme.bodyFontSize)
        : getFontSize('small')};
    font-weight: bold;
    letter-spacing: 0.5px;
    padding: ${rem(12)} ${rem(32)} ${rem(11)};
    border-radius: ${rem(25)};
    transition: all ${durations.fast} ease-out;

    ${props => (props.fill ? 'display: block' : 'display: inline-block')};
    ${({ fluid }) =>
      fluid &&
      `
      width: 100%;
    `};

    & > a {
      text-align: center;
    }
  }
`

const Icon = styled.span`
  flex: 0 0 ${rem(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(40)};
  height: 100%;
  padding-left: ${({ iconRightSide }) => !iconRightSide && rem(4)};
  padding-right: ${({ iconRightSide }) => iconRightSide && rem(4)};
`
const Inner = styled.span`
  ${({ hasIcon, iconRightSide }) =>
    hasIcon &&
    `
    margin-left: ${iconRightSide ? 0 : rem(32)}
    margin-right: ${iconRightSide ? rem(32) : 0}
  `};
`

export const PrimaryButton = styled(Base)`
  && {
    background-image: ${({ theme }) =>
      `linear-gradient(
          to top,
          ${theme.primaryButtonMainColor},
          ${theme.primaryButtonAlternativeColor}
        )`}};
    color: ${({ theme }) => theme.buttonPrimaryTextColor};
    ${({ theme }) => focusOutline(theme.body)};

    &:hover {
      background-image: ${({ theme }) =>
        `linear-gradient(
           to top,
           ${theme.primaryButtonAlternativeColor},
           ${theme.primaryButtonAlternativeColor}
         )`};
      color: ${({ theme }) => theme.buttonPrimaryTextColor};
    }

    &:active {
      background-image: ${({ theme }) => {
        if (theme.name !== 'lgbt') {
          return `linear-gradient(
               to top,
               ${theme.primaryButtonMainColor},
               ${theme.primaryButtonMainColor}
            )`
        } else {
          return `linear-gradient(
               to top,
               ${theme.primaryButtonAlternativeColor},
               ${theme.primaryButtonAlternativeColor}
            )`
        }
      }};
      color: ${({ theme }) => theme.buttonPrimaryTextColor};
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  ${Icon} {
    position: absolute;
    width: ${rem(40)};
    top: 0;
    bottom: 0;

    ${({ iconRightSide }) =>
      iconRightSide &&
      `
      right: 0;
    `}
  }
`

export const SecondaryButton = styled(Base)`
  && {
    background-image: ${({ theme }) =>
      `linear-gradient(
        to top,
        ${theme.secondaryButtonMainColor},
        ${theme.secondaryButtonAlternativeColor}
      )`};
    color: ${({ theme }) => theme.buttonSecondaryTextColor};
    ${({ theme }) => focusOutline(theme.body)};

    &:hover {
      color: ${({ theme }) => theme.buttonSecondaryTextColorHover};
      background-image: ${({ theme }) =>
        `linear-gradient(
          to top,
          ${theme.secondaryButtonAlternativeColor},
          ${theme.secondaryButtonAlternativeColor}
        )`};
    }

    &:active {
      color: ${({ theme }) => theme.buttonSecondaryTextColor};
      background-image: ${({ theme }) =>
        `linear-gradient(
          to bottom,
          ${theme.secondaryButtonMainColor},
          ${theme.secondaryButtonMainColor}
        )`};
    }

    &[disabled] {
      opacity: 0.5;
    }

    ${Icon} {
      position: absolute;
      width: ${rem(40)};
      top: 0;
      bottom: 0;
    }
  }
`

export const NormalButton = styled(Base)`
  && {
    border-width: ${rem(2)};
    border-style: solid;
    //TODO: It's missing to use the colors from the specific themes here instead of the shared ones that come from color
    ${props =>
      props.negative
        ? `
        color: ${props.theme.buttonPrimaryColor};
        border-color: ${props.theme.buttonPrimaryColor};
      `
        : `
        color: ${props.theme.buttonSecondaryColor};
        border-color: ${props.theme.buttonSecondaryColor};
      `}
    //TODO
    &:hover,
    &:active {
      ${props =>
        props.negative
          ? `
          color: ${props.theme.buttonSecondaryTextColorNegativeHover};
          border-color: ${props.theme.buttonPrimaryColor};
          background-color: ${props.theme.buttonPrimaryColor};
        `
          : `
          color: ${props.theme.buttonPrimaryTextColor};
          border-color: ${props.theme.buttonSecondaryColor};
          background-color: ${props.theme.buttonSecondaryColor};
        `}
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  ${Icon} {
    position: absolute;
    width: ${rem(40)};
    top: 0;
    bottom: 0;
  }
`

export const FilterButton = styled(Base)`
  && {
    border-radius: ${rem(62)};

    background-color: #c4e1e4;
    color: #003851;
    padding: 8px 16px;
    flex: none;
    max-width: 100%;

    //TODO
    &:hover,
    &:active {
      ${props =>
        props.negative
          ? `
          color: ${props.theme.buttonSecondaryTextColorNegativeHover};
          border-color: ${props.theme.buttonPrimaryColor};
          background-color: ${props.theme.buttonPrimaryColor};
        `
          : `
          color: ${props.theme.buttonPrimaryTextColor};
          border-color: ${props.theme.buttonSecondaryColor};
          background-color: ${props.theme.buttonSecondaryColor};
        `}
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  ${Icon} {
    position: absolute;
    width: ${rem(40)};
    top: 0;
    bottom: 0;
  }
`

function getButton(type: ButtonTypes) {
  switch (type) {
    case 'primary':
      return PrimaryButton
    case 'secondary':
      return SecondaryButton
    case 'filter':
      return FilterButton
    case 'normal':
    default:
      return NormalButton
  }
}

const Button = ({
  children,
  label,
  icon,
  iconRightSide,
  type,
  loading,
  ...rest
}: Props) => {
  const ButtonType = getButton(type)
  const theme = React.useContext(ThemeContext)

  return (
    <ButtonType
      aria-label={label}
      iconRightSide={iconRightSide}
      theme={theme}
      {...rest}
    >
      {icon && <Icon iconRightSide={iconRightSide}>{icon}</Icon>}
      <Inner hasIcon={!!icon} iconRightSide={iconRightSide}>
        {loading ? null : children || label}
      </Inner>
    </ButtonType>
  )
}

Button.displayName = 'Button'

export default Button
