// @flow
import type { ThemeTypes } from '../types/enums/ThemeTypes'
import type { ThemeViewModel } from '../types/ThemeViewModel'
import { rgba } from 'polished'
import { durations } from './animations'

// Color palette used in the project
// color names are generated using - http://chir.ag/projects/name-that-color
export const colorPalette = {
  black: '#191919',
  blue: '#31a7cd',
  botticelli: '#C4E1E4',
  edgewater: '#CFE6E1',
  green: '#49a478',
  grey: '#b1b3b6',
  heather: '#ACC0C8',
  hippiePink: '#a53f4f',
  hummingBird: '#c6ecf7',
  keppel: '#2fb0ab',
  lightBlue: '#c4e1e4',
  lightGreen: '#2ed19B',
  lightYellow: '#ffcc00',
  mystic: '#e3e8ec',
  periglacialBlue: '#e0e5d6',
  pictonBlue: '#51c8f0',
  prussianBlue: '#003851',
  red: '#cf5047',
  scooter: '#31A7CD',
  spray: '#8dd9f0',
  tealBlue: '#023a51',
  tiara: '#c9d5d7',
  white: '#FFF',
  yellow: '#e09a14',
  gumbo: '#809ca8',
  lola: '#dacbd6',
  purple: '#5c4c78', //OURS 6a2f5d
  darkPurple: '#3d3250', //(from client for LGBT) //OURS  403152
  lightPurple: '#cac2d8', //(from client for LGBT) // OURS 604a7b
  darkGrey: '#2B2E34', //our suggestion for LGBT
  lightGrey: '#edf1f2', //f2f2f2 (from client for LGBT)
  cardGrey: '#DDDDDD', //a6a6a6 (from client for LGBT) // DDDDDD our suggestion
  orange: '#e46c0a', //(from client for LGBT)
  darkYellow: '#ffc000', //(from client for LGBT)
  blueWhale: '#003145',
}

// Colors are given business variables
export const color = {
  primary: colorPalette.darkGrey, //TODO: Kept here temporarily because of css files which consume it.
  body: colorPalette.darkGrey, //TODO: Kept here temporarily because of css files which consume it.
  bg: colorPalette.lightGrey,
  accent: colorPalette.yellow, //TODO: Kept here temporarily because of css files which consume it.
  disabled: colorPalette.grey,
  fieldNormal: colorPalette.heather, //TODO: Remove it from here when tested and ok
  fieldActive: colorPalette.scooter, //TODO: Remove it from here when tested and ok
  fieldHover: colorPalette.prussianBlue, //TODO: Remove it from here when tested and ok
  fieldError: colorPalette.red,
  responseTextAllowed: colorPalette.lightGreen,
  responseTextNotAllowed: colorPalette.red,
  ...colorPalette,
}

export const fontFamily =
  'MuseoSans, system-ui, Helvetica Neue, Arial, sans-serif'

export const secondaryFontFamily =
  'MuseoSansRounded, system-ui, Helvetica Neue, Arial, sans-serif'

type ThemesObject = {
  main: ThemeViewModel,
  kids: ThemeViewModel,
  lgbt: ThemeViewModel,
  notspecified: ThemeViewModel,
}

export const DEFAULT_THEME: ThemeTypes = 'main'

export const themes: ThemesObject = {
  main: {
    name: 'main',
    fontFamily: fontFamily,
    borderRadius: 8,
    logoText: 'Familieretshuset',
    logoTextColor: colorPalette.prussianBlue,
    logoTextColorHover: color.blue,
    phoneTextColorHover: color.blue,
    phoneTextAccentColorHover: color.blue,
    bgColor: color.lightGrey,
    bodyFontSize: 16,
    bodyFontWeight: 300,
    primary: colorPalette.prussianBlue,
    body: colorPalette.prussianBlue,
    buttonPrimaryColor: colorPalette.white,
    buttonSecondaryColor: colorPalette.prussianBlue,
    buttonPrimaryTextColor: colorPalette.white,
    buttonSecondaryTextColor: colorPalette.prussianBlue,
    buttonSecondaryTextColorHover: colorPalette.prussianBlue,
    buttonSecondaryTextColorNegativeHover: colorPalette.prussianBlue,
    borderPositive: rgba(colorPalette.prussianBlue, 0.16),
    borderPositiveInvert: rgba(colorPalette.white, 0.16),
    borderNegative: rgba(colorPalette.white, 0.16),
    primaryButtonMainColor: colorPalette.green,
    primaryButtonAlternativeColor: colorPalette.lightGreen,
    secondaryButtonMainColor: colorPalette.yellow,
    secondaryButtonAlternativeColor: colorPalette.lightYellow,
    articleBackground: color.tiara,
    cardNegativeBackground: color.lightBlue,
    cardPositiveBackground: color.white,
    text: colorPalette.prussianBlue,
    textInvert: colorPalette.white,
    accordionBodyStyle: colorPalette.lightBlue,
    oldDate: colorPalette.red,
    quote: colorPalette.lightBlue,
    cookieText: colorPalette.prussianBlue,
    stepCompletedBg: colorPalette.keppel,
    stepActivedBg: colorPalette.prussianBlue,
    stepDisableParagraph: colorPalette.heather,
    fieldNormal: colorPalette.blueWhale, //heather,
    fieldActive: colorPalette.scooter,
    fieldHover: colorPalette.prussianBlue,
    checkMarkChecked: colorPalette.scooter,
    cookieFooter: colorPalette.mystic,
    cookieSliderNormal: colorPalette.heather,
    cookieSliderActive: colorPalette.blue,
    paginationBackground: colorPalette.heather,

    accent: color.yellow, // to be decided
  },
  kids: {
    name: 'kids',
    fontFamily: secondaryFontFamily,
    borderRadius: 16,
    logoText: 'Mig i midten',
    logoTextColor: colorPalette.prussianBlue,
    logoTextColorHover: color.blue,
    phoneTextColorHover: color.blue,
    phoneTextAccentColorHover: color.blue,
    bgColor: color.hummingBird,
    bodyFontSize: 18,
    bodyFontWeight: 500,
    primary: colorPalette.prussianBlue,
    body: colorPalette.prussianBlue,
    buttonPrimaryColor: colorPalette.white,
    buttonSecondaryColor: colorPalette.prussianBlue,
    buttonPrimaryTextColor: colorPalette.white,
    buttonSecondaryTextColor: colorPalette.prussianBlue,
    buttonSecondaryTextColorHover: colorPalette.prussianBlue,
    buttonSecondaryTextColorNegativeHover: colorPalette.prussianBlue,
    borderPositive: rgba(colorPalette.prussianBlue, 0.16),
    borderPositiveInvert: rgba(colorPalette.white, 0.16),
    borderNegative: rgba(colorPalette.white, 0.16),
    primaryButtonMainColor: colorPalette.green,
    primaryButtonAlternativeColor: colorPalette.lightGreen,
    secondaryButtonMainColor: colorPalette.yellow,
    secondaryButtonAlternativeColor: colorPalette.lightYellow,
    articleBackground: color.spray,
    cardNegativeBackground: color.lightBlue,
    cardPositiveBackground: color.white,
    text: colorPalette.prussianBlue,
    textInvert: colorPalette.white,
    accordionBodyStyle: colorPalette.lightBlue,
    oldDate: colorPalette.red,
    quote: colorPalette.lightYellow,
    cookieText: colorPalette.white,
    stepCompletedBg: colorPalette.keppel,
    stepActivedBg: colorPalette.prussianBlue,
    stepDisableParagraph: colorPalette.heather,
    fieldNormal: colorPalette.blueWhale, //heather,
    fieldActive: colorPalette.scooter,
    fieldHover: colorPalette.prussianBlue,
    checkMarkChecked: colorPalette.scooter,
    cookieFooter: colorPalette.mystic,
    cookieSliderNormal: colorPalette.heather,
    cookieSliderActive: colorPalette.blue,
    paginationBackground: colorPalette.heather,

    accent: color.lightYellow, // to be decided
  },
  lgbt: {
    name: 'lgbt',
    logoText: 'LGBTfamilie',
    fontFamily: fontFamily,
    bodyFontSize: 18,
    bodyFontWeight: 500,
    borderRadius: 16,
    bgColor: colorPalette.lightGrey,
    logoTextColor: colorPalette.darkGrey,
    logoTextColorHover: color.lightPurple,
    phoneTextColorHover: color.darkYellow,
    phoneTextAccentColorHover: color.lightPurple,
    primary: colorPalette.darkPurple,
    body: colorPalette.darkGrey,
    buttonPrimaryColor: colorPalette.white,
    buttonSecondaryColor: colorPalette.purple,
    buttonPrimaryTextColor: colorPalette.white,
    buttonSecondaryTextColor: colorPalette.white,
    buttonSecondaryTextColorHover: colorPalette.darkYellow,
    buttonSecondaryTextColorNegativeHover: colorPalette.purple,
    borderPositive: rgba(colorPalette.darkPurple, 0.16),
    borderPositiveInvert: rgba(colorPalette.cardGrey, 0.16),
    borderNegative: rgba(colorPalette.white, 0.16),
    primaryButtonMainColor: colorPalette.orange,
    primaryButtonAlternativeColor: colorPalette.darkYellow,
    secondaryButtonMainColor: colorPalette.darkPurple,
    secondaryButtonAlternativeColor: colorPalette.purple,
    articleBackground: color.purple,
    cardNegativeBackground: colorPalette.cardGrey,
    cardPositiveBackground: color.white,
    text: colorPalette.darkGrey,
    textInvert: colorPalette.white,
    accordionBodyStyle: colorPalette.lightGrey,
    oldDate: colorPalette.red,
    quote: colorPalette.darkYellow,
    cookieText: colorPalette.white,
    stepCompletedBg: colorPalette.lightPurple,
    stepActivedBg: colorPalette.purple,
    stepDisableParagraph: colorPalette.grey,
    fieldNormal: colorPalette.lightPurple,
    fieldActive: colorPalette.purple,
    fieldHover: colorPalette.darkPurple,
    checkMarkChecked: colorPalette.purple,
    cookieFooter: colorPalette.lightGrey,
    cookieSliderNormal: colorPalette.lightPurple,
    cookieSliderActive: colorPalette.purple,
    paginationBackground: colorPalette.lightPurple,

    accent: color.darkYellow, // to be decided
  },
  notspecified: {
    name: 'notspecified',
    fontFamily: fontFamily,
    borderRadius: 8,
    logoText: 'Familieretshuset',
    logoTextColor: colorPalette.prussianBlue,
    logoTextColorHover: color.blue,
    phoneTextColorHover: color.blue,
    phoneTextAccentColorHover: color.blue,
    bgColor: color.lightGrey,
    bodyFontSize: 16,
    bodyFontWeight: 300,
    primary: colorPalette.prussianBlue,
    body: colorPalette.prussianBlue,
    buttonPrimaryColor: colorPalette.white,
    buttonSecondaryColor: colorPalette.prussianBlue,
    buttonPrimaryTextColor: colorPalette.white,
    buttonSecondaryTextColor: colorPalette.prussianBlue,
    buttonSecondaryTextColorHover: colorPalette.prussianBlue,
    buttonSecondaryTextColorNegativeHover: colorPalette.prussianBlue,
    borderPositive: rgba(colorPalette.prussianBlue, 0.16),
    borderPositiveInvert: rgba(colorPalette.white, 0.16),
    borderNegative: rgba(colorPalette.white, 0.16),
    primaryButtonMainColor: colorPalette.green,
    primaryButtonAlternativeColor: colorPalette.lightGreen,
    secondaryButtonMainColor: colorPalette.yellow,
    secondaryButtonAlternativeColor: colorPalette.lightYellow,
    articleBackground: color.tiara,
    cardNegativeBackground: color.lightBlue,
    cardPositiveBackground: color.white,
    text: colorPalette.prussianBlue,
    textInvert: colorPalette.white,
    accordionBodyStyle: colorPalette.lightBlue,
    oldDate: colorPalette.red,
    quote: colorPalette.lightBlue,
    cookieText: colorPalette.prussianBlue,
    stepCompletedBg: colorPalette.keppel,
    stepActivedBg: colorPalette.prussianBlue,
    stepDisableParagraph: colorPalette.heather,
    fieldNormal: colorPalette.heather,
    fieldActive: colorPalette.scooter,
    fieldHover: colorPalette.prussianBlue,
    checkMarkChecked: colorPalette.scooter,
    cookieFooter: colorPalette.lightBlue,
    cookieSliderNormal: colorPalette.heather,
    cookieSliderActive: colorPalette.blue,
    paginationBackground: colorPalette.heather,

    accent: color.yellow,
  },
}

export const effects = (theme: any) => ({
  bottomShadow: '0 0 9px 2px rgba(20, 20, 60, 0.1)',
  cardShadow: '0 2px 9px 0 rgba(20, 20, 60, 0.1)',
  borderPositive: theme && `1px solid ${theme.borderPositive}`,
  borderPositiveInvert: theme && `1px solid ${theme.borderPositiveInvert}`,
  borderNegative: theme && `1px solid ${theme.borderNegative}`,
  linkHoverTransition: `color ${durations.fast} ease-in-out`,
})
