// @flow
import * as React from 'react'
import type { HtmlConfigViewModel } from '../types/HtmlConfigViewModel'
import type { MetaViewModel } from '../types/MetaViewModel'
import AppShell from './AppShell'
import type { ModuleInstance } from '../modules/modules-utils'
import { withRouter } from './Router'
import { hot } from 'react-hot-loader'
import { fetchHeadlessPage } from './page-loader'
import ScrollToElement from '../utils/ScrollToElement'
import type { Location } from './Router'

type Props = {
  config: HtmlConfigViewModel,
  initialModules: Array<ModuleInstance>,
  initialMeta: MetaViewModel,
  location: Location,
}

type State = {
  error: ?Error,
  errorInfo: ?Object,
  meta: MetaViewModel,
  modules: Array<ModuleInstance>,
  // The actual pathname of the current page. Only updated once the modules changes
  pathname: string,
  fetching: ?string,
}

function buildUrl(location: Location) {
  const { pathname, search, hash } = location

  return [pathname]
    .concat(search || [])
    .concat(hash || [])
    .join('')
}

class App extends React.PureComponent<Props, State> {
  static displayName = 'App'
  static defaultProps = {
    initialMeta: {},
    initialModules: [],
  }

  state = {
    error: null,
    errorInfo: null,
    pathname: this.props.location.pathname,
    modules: this.props.initialModules,
    meta: this.props.initialMeta,
    fetching: null,
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.modules !== this.state.modules) {
      // Scroll to the top of the page when modules have changed.
      window.scrollTo(0, 0)
      // If the page has a hash value, scroll to it.
      if (this.props.location.hash) {
        ScrollToElement(this.props.location.hash)
      }
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      // If the location changed, we need to fetch the new data
      this.fetchNewPage(this.props.location.pathname)
    } else if (
      prevProps.location.hash !== this.props.location.hash &&
      this.props.location.hash
    ) {
      // If the hash changed, tried to scroll to the new element
      ScrollToElement(this.props.location.hash)
    }
  }

  componentDidCatch(error: Error, info: { componentStack: string }) {
    // We need to handle the error
    this.setState({ error, errorInfo: info })
  }

  async fetchNewPage(pathname: string) {
    this.setState({ fetching: pathname })
    const result = await fetchHeadlessPage(pathname, this.props.config.headless)

    if (result) {
      // If the pathname hasn't changed since load began, update the state
      if (result.pathname === this.props.location.pathname) {
        this.setState({
          meta: result.meta,
          modules: result.modules,
          pathname,
          fetching: null,
        })
      }
    } else if (pathname === this.props.location.pathname) {
      // If we didn't get a valid result, and the pathname hasn't changed, do a hard page change
      if (global.location) global.location.href = buildUrl(this.props.location)
    }
  }

  render() {
    const { config } = this.props
    const { meta, modules, fetching, pathname } = this.state

    return (
      <AppShell
        meta={meta}
        modules={modules}
        pathname={pathname}
        isLoading={!!fetching}
        {...config}
      />
    )
  }
}

export default hot(module)(withRouter(App))
