// @flow
import * as React from 'react'

type MediaQueryList = {
  matches: boolean,
  addListener: (handler: Function) => void,
  removeListener: (handler: Function) => void,
}

type Props = {
  defaultMatches: boolean,
  query: string | Object | Array<Object>,
  render?: Function,
  children?: React.Node | Function,
  clientOnly?: boolean,
}

type State = {
  matches: boolean,
}

/**
 * Conditionally renders based on whether or not a media query matches.
 * Adapted from: https://github.com/ReactTraining/react-media
 */
class MediaQuery extends React.Component<Props, State> {
  static defaultProps = {
    defaultMatches: true,
  }

  constructor(props: Props) {
    super(props)
    if (props.clientOnly && !!global.document) {
      // If just rendering clientside, add the matcher now so state reflects the correct query from the start
      this.addMatcher()
      this.state = {
        matches: this.mediaQueryList.matches,
      }
    } else {
      this.state = {
        matches: this.props.defaultMatches,
      }
    }
  }

  componentDidMount() {
    if (!this.mediaQueryList) {
      this.addMatcher()
      this.updateMatches()
    }
  }

  componentDidUpdate(lastProps: Props) {
    if (this.props.query !== lastProps.query) {
      this.addMatcher()
      this.updateMatches()
    }
  }

  componentWillUnmount() {
    if (this.mediaQueryList) {
      this.mediaQueryList.removeListener(this.updateMatches)
    }
  }

  mediaQueryList: MediaQueryList

  addMatcher() {
    if (this.mediaQueryList) {
      this.mediaQueryList.removeListener(this.updateMatches)
    }
    this.mediaQueryList = window.matchMedia(this.props.query)
    this.mediaQueryList.addListener(this.updateMatches)
  }

  updateMatches = () => this.setState({ matches: this.mediaQueryList.matches })

  render() {
    const { children, render } = this.props
    const { matches } = this.state

    return render
      ? matches
        ? render()
        : null
      : children
      ? typeof children === 'function'
        ? children(matches)
        : !Array.isArray(children) || children.length // Preact defaults to empty children array
        ? matches
          ? React.Children.only(children)
          : null
        : null
      : null
  }
}

export default MediaQuery
