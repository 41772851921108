// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { animated } from 'react-spring/web.cjs'

import { color, effects } from '../../styles/theme'
import { getFontSize } from '../../styles/style-helpers'
import { mediaOnly } from '../../styles/media'

import BaseButton from '../Button/BaseButton'
import InfoIcon from '../../icons/InfoIcon'
import RemoveIcon from '../../icons/RemoveIcon'

const TOAST_TYPES = {
  ERROR: {
    color: 'red',
  },
  WARNING: {
    color: 'yellow',
  },
  INFO: {
    color: 'blue',
  },
}

type Props = {
  message: string,
  actions?: Array<Action>,
  onClick?: Function,
  style?: Object,
  type?: string,
  contentRef?: Function,
}

type Action = {
  label: string,
  handleClick: Function,
  closeOnClick?: boolean,
}

const ToastContainer = styled(animated.div)`
  z-index: 20;
  pointer-events: all;
  width: 385px;

  ${mediaOnly.xs`
    width: 100%;
  `}
`

const StyledInfoIcon = styled(InfoIcon)`
  transition: opacity 0.1s ease-in-out;
`
const StyledRemoveIcon = styled(RemoveIcon)`
  transition: opacity 0.1s ease-in-out;
  margin-left: -24px;
`

const Content = styled.div`
  overflow: hidden;
  background: ${({ typeColor }) => color[typeColor]};
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  padding: ${rem(10)} ${rem(15)};
  border-radius: ${({ theme }) =>
    theme.borderRadius ? `${rem(theme.borderRadius)}` : rem(8)};
  opacity: 0.8;
  transition: opacity 0.1s ease-in-out;
  cursor: pointer;

  ${StyledRemoveIcon} {
    opacity: 0;
  }

  &:hover {
    opacity: 1;

    ${StyledInfoIcon} {
      opacity: 0;
    }

    ${StyledRemoveIcon} {
      opacity: 1;
    }
  }

  ${mediaOnly.xs`
    opacity: 1;

    ${StyledInfoIcon} {
      opacity: 0;
    }

    ${StyledRemoveIcon} {
      opacity: 1;
    }
  `}

  p {
    margin: 0;
  }
`

const Message = styled.div`
  flex: 2;
  margin-left: ${rem(15)};
  margin-right: ${rem(10)};
  font-size: ${getFontSize('small')};
`

const ActionBar = styled.div`
  display: flex;
  max-width: 30%;
  height: 100%;
  padding-left: ${rem(10)};
  border-left: ${({ theme }) => effects(theme).borderNegative};
`

const ActionButton = styled(BaseButton)`
  font-size: ${getFontSize('small')};
  transition: all 0.1s ease-in-out;
  text-transform: uppercase;

  :active {
    transform: scale(0.9);
  }
`

const Toast = React.forwardRef<Props, HTMLDivElement>(
  (
    {
      message,
      type = 'WARNING',
      actions = [],
      onClick,
      style,
      contentRef,
      ...rest
    }: Props,
    ref,
  ) => {
    return (
      <ToastContainer ref={ref} style={style} {...rest}>
        <Content
          ref={contentRef}
          onClick={onClick}
          typeColor={TOAST_TYPES[type].color}
        >
          <StyledInfoIcon width={24} height={24} />
          <StyledRemoveIcon width={24} height={24} />

          <Message>
            <p>{message}</p>
          </Message>
          {actions.length > 0 && (
            <ActionBar>
              {actions.map((action, index) => (
                <ActionButton
                  key={index}
                  onClick={e => {
                    if (!action.closeOnClick) {
                      e.stopPropagation()
                    }
                    action.handleClick()
                  }}
                >
                  {action.label}
                </ActionButton>
              ))}
            </ActionBar>
          )}
        </Content>
      </ToastContainer>
    )
  },
)

Toast.displayName = 'Toast'

export default Toast
