// @flow
import * as React from 'react'
import styled from 'styled-components'
import { useTransition } from 'react-spring/web.cjs'

import { NotificationsContext } from '../NotificationsContext/NotificationsContext'
import Toast from '../Toast/Toast'

type Props = {
  children?: React.Node,
}

export const Container = styled('div')`
  position: fixed;
  z-index: 1000;
  width: 0 auto;
  top: ${props => (props.top ? '30px' : 'unset')};
  bottom: ${props => (props.top ? 'unset' : '30px')};
  margin: 0 auto;
  left: 30px;
  right: 30px;
  display: flex;
  flex-direction: ${props => (props.top ? 'column-reverse' : 'column')};
  pointer-events: none;
  align-items: ${props =>
    props.position === 'center' ? 'center' : `flex-${props.position || 'end'}`};
  @media (max-width: 680px) {
    align-items: center;
  }
`

function ToastContainer({ children, ...rest }: Props) {
  const { notifications, removeNotification } = React.useContext(
    NotificationsContext,
  )

  const [refMap] = React.useState(() => new WeakMap())
  const transitions = useTransition(notifications, item => item.id, {
    from: {
      height: '0px',
      opacity: 1,
      marginTop: '0px',
      filter: 'blur(50px)',
    },
    enter: item => async next => {
      await next({
        // $FlowFixMe
        height: `${refMap.get(item).offsetHeight}px`,
        marginTop: '10px',
        filter: 'blur(0px)',
        opacity: 1,
      })
    },
    leave: item => async next => {
      await next({
        opacity: 0,
        filter: 'blur(50px)',
      })
      await next({
        height: '0px',
        marginTop: '0px',
      })
    },
    config: {
      tension: 210,
      friction: 24,
    },
  })

  return (
    <Container {...rest}>
      {transitions.map(({ item, props, key }) => {
        return (
          <Toast
            key={key}
            contentRef={ref => ref && refMap.set(item, ref)}
            type={item.type}
            message={item.message}
            actions={item.actions}
            onClick={() => removeNotification(item.id)}
            style={props}
          />
        )
      })}
    </Container>
  )
}

ToastContainer.displayName = 'ToastContainer'
ToastContainer.defaultProps = {
  position: 'center',
}

export default ToastContainer
