// @flow
import * as React from 'react'
import styled from 'styled-components'
import { withRouter } from '../../App/Router'
import type { LinkViewModel } from '../../types/LinkViewModel'
import type { Location, History } from '../../App/Router'
import { focusOutline } from '../../styles/style-helpers'
import { effects } from '../../styles/theme'

type Props = LinkViewModel & {
  children?: React.Node,
  onClick?: Function,
  /** Replace the current history, instead of pushing a new location */
  replace?: boolean,
  location?: Location,
  history?: History,
  underline?: boolean,
  invert?: boolean,
  fontColor?: string,
}

const isDifferentSubdomain = (nextUrl?: string) => {
  if (!nextUrl) {
    return false
  }

  const matches = nextUrl.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i) // eslint-disable-line
  const nextSubdomain = matches && matches[1] // domain will be null if no match is found

  const currentSubdomain =
    global.location && global.location.hostname.split('.')[0]
  return nextSubdomain !== currentSubdomain
}

export function isExternalLink(href?: string) {
  if (!href) return false
  const isHttp = href.indexOf('http') === 0

  return (
    !/^[#|/]/.test(href) &&
    isHttp &&
    (isDifferentSubdomain(href) ||
      (global.location && !href.includes(global.location.hostname)))
  )
}

function isContactLink(href?: string) {
  if (!href) return false

  return href.startsWith('mailto:') || href.startsWith('tel:')
}

const Anchor = styled.a`
  color: ${({ theme, invert, fontColor }) =>
    fontColor ? fontColor : invert ? theme.textInvert : theme.primary};
  outline-offset: 1px;
  text-decoration: ${p => (p.underline ? 'underline' : 'none')};
  transition: ${effects().linkHoverTransition};
  ${focusOutline('currentColor')};

  &:hover {
    color: ${({ theme }) => theme.logoTextColorHover};
  }
`

/**
 * The public API for rendering a history-aware <a>.
 */

export class Link extends React.Component<Props> {
  static displayName = 'Link'
  static defaultProps = {
    replace: false,
  }

  static isModifiedEvent(event: SyntheticMouseEvent<HTMLAnchorElement>) {
    return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
  }

  handleClick = (event: SyntheticMouseEvent<HTMLAnchorElement>) => {
    const { replace, href, onClick, target, history } = this.props

    if (isContactLink(href)) return null // let browser handle contact links

    if (onClick) {
      onClick(event)
    }

    if (
      !isExternalLink(href) &&
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      target !== '_blank' && // let browser handle "target=_blank" etc.
      !Link.isModifiedEvent(event) && // ignore clicks with modifier keys
      !!history &&
      !!history.push
    ) {
      // Block the normal anchor event
      event.preventDefault()
      if (replace && !!history.replace) {
        history.replace(href)
      } else {
        history.push(href)
      }
    }
  }
  render() {
    const {
      history,
      replace,
      location,
      underline,
      active,
      children,
      label,
      href,
      target,
      invert,
      fontColor,
      ...rest
    } = this.props

    let controlledTarget = target
    if (!target) {
      controlledTarget = isExternalLink(href) ? '_blank' : '_self'
    }

    return (
      <Anchor
        aria-current={active ? 'page' : undefined}
        title={label}
        {...rest}
        underline={underline}
        href={href}
        target={controlledTarget}
        rel={controlledTarget === '_blank' ? 'noopener noreferrer' : undefined}
        invert={invert}
        fontColor={fontColor}
      >
        {children}
      </Anchor>
    )
  }
}

export const RouterLink = withRouter(Link)
