// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import consecutive from 'consecutive'
import { Link } from 'react-scroll'

import type { AreaTypes } from '../../types/enums/AreaTypes'
import type { AccordionViewModel } from '../../types/AccordionViewModel'

import { color, effects } from '../../styles/theme'
import Ribbon from '../../components/Ribbon/Ribbon'
import Sidebar from '../../components/Sidebar/Sidebar'
import Heading from '../../components/Heading/Heading'
import MediaQuery from '../../components/MediaQuery/MediaQuery'
import { getFontSize } from '../../styles/style-helpers'
import { mediaQuery } from '../../styles/media'

export type Props = {
  area?: AreaTypes,
  children?: React.Node,
  sidebar?: Array<React.Node>,
  anchorItems?: Array<AccordionViewModel>,
}

type State = {}

const keyGen = consecutive()

const NarrowWrapper = styled.div`
  background-color: ${color.white};
  margin: 0 -30px;
  padding: ${rem(64)} ${rem(30)};
  ${mediaQuery.sm`
    margin: 0;  
    border-radius: ${rem(8)};
    padding: ${rem(96)} ${rem(80)} ${rem(120)} ${rem(96)};
  `}

  @media print {
    margin: 0;
  }
`

const NarrowWrapperInner = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
  }
`

const NarrowContent = styled.div`
  width: 100%;
  ${mediaQuery.md`
    margin-right: ${rem(64)};
  `}
`

const NarrowSidebar = styled.div`
  margin: 0;
  max-width: ${rem(224)};
  min-width: ${rem(224)};
`

const NarrowSidebarDevice = styled.div`
  margin-bottom: ${rem(64)};
`

const NarrowNavigation = styled.div`
  margin-bottom: ${rem(40)};

  & > a,
  & > button {
    margin-bottom: ${rem(16)};
  }
`

const NarrowNavigationTitle = styled(Heading)`
  font-size: ${getFontSize('normal')};
`

const NarrowNavigationList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const NarrowNavigationListItem = styled.li`
  margin: 0 0 ${rem(24)};
  padding: 0;
  color: ${({ theme }) => theme.primary};
`

const NarrowNavigationListLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  transition: ${effects().linkHoverTransition};
  color: ${({ theme }) => theme.primary};

  &:hover {
    color: ${color.accent};
  }
`

class Narrow extends React.Component<Props, State> {
  static displayName = 'Narrow'
  static defaultProps = {}

  render() {
    const { children, sidebar, anchorItems } = this.props

    return (
      <Ribbon clearChildMargin className={'print'}>
        <NarrowWrapper>
          <NarrowWrapperInner>
            <MediaQuery query={`(max-width: 1024px)`} defaultMatches={false}>
              <NarrowSidebarDevice>{sidebar && sidebar}</NarrowSidebarDevice>
            </MediaQuery>
            <NarrowContent>{children && children}</NarrowContent>
            <MediaQuery query={`(min-width: 1025px)`} defaultMatches>
              {sidebar && sidebar.length ? (
                <NarrowSidebar>
                  <Sidebar>
                    {anchorItems && anchorItems.length && (
                      <NarrowNavigation>
                        <NarrowNavigationTitle
                          tag="h4"
                          level={5}
                          responsiveSize={true}
                        >
                          Indhold
                        </NarrowNavigationTitle>
                        <NarrowNavigationList>
                          {anchorItems.map((item, index) => {
                            return (
                              <NarrowNavigationListItem
                                key={`NarrowNavigationListItem-${keyGen()}`}
                              >
                                <NarrowNavigationListLink
                                  className="NarrowNavigationListLink"
                                  to={`${(item.anchor && item.anchor.id) ||
                                    ''}`}
                                  offset={-300}
                                  spy={true}
                                  smooth
                                >
                                  {item.anchor && item.anchor.label}
                                </NarrowNavigationListLink>
                              </NarrowNavigationListItem>
                            )
                          })}
                        </NarrowNavigationList>
                      </NarrowNavigation>
                    )}
                    {sidebar && sidebar}
                  </Sidebar>
                </NarrowSidebar>
              ) : null}
            </MediaQuery>
          </NarrowWrapperInner>
        </NarrowWrapper>
      </Ribbon>
    )
  }
}

export default Narrow
