/* eslint-disable no-extend-native */
// @flow
import 'unfetch/polyfill/index'
import { shouldPolyfill as shouldPolyfillListFormat } from '@formatjs/intl-listformat/should-polyfill'

/**
 * Load additional polyfills here
 **/
function loadPolyfills(): Promise<*> {
  addShims()
  const polyfills = []

  if (shouldPolyfillListFormat()) {
    polyfills.push(
      import(
        /* webpackChunkName: 'intl-listformat' */ '@formatjs/intl-listformat/polyfill'
      ),
    )
    polyfills.push(
      /* webpackChunkName: 'intl-listformat-locale-da' */ import(
        '@formatjs/intl-listformat/locale-data/da'
      ),
    )
  }

  if (!supportsIntersectionObserver()) {
    polyfills.push(
      import(
        /* webpackChunkName: 'intersection-observer' */ 'intersection-observer'
      ),
    )
  }
  if (!supportsSrcSet()) {
    polyfills.push(
      import(/* webpackChunkName: 'picturefill' */ './picturefill'),
    )
  }

  return Promise.all(polyfills)
}

function supportsIntersectionObserver() {
  return (
    'IntersectionObserver' in global &&
    'IntersectionObserverEntry' in global &&
    'intersectionRatio' in global.IntersectionObserverEntry.prototype
  )
}

function supportsSrcSet() {
  const img = document.createElement('img')
  return 'sizes' in img
}

// https://tc39.github.io/ecma262/#sec-array.prototype.findindex
if (!Array.prototype.findIndex) {
  // $FlowFixMe - Allow this
  Object.defineProperty(Array.prototype, 'findIndex', {
    value: function(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined')
      }

      var o = Object(this)

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function')
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1]

      // 5. Let k be 0.
      var k = 0

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        var kValue = o[k]
        if (predicate.call(thisArg, kValue, k, o)) {
          return k
        }
        // e. Increase k by 1.
        k++
      }

      // 7. Return -1.
      return -1
    },
    configurable: true,
    writable: true,
  })
}

function addShims() {
  /**
   * Shim the requestIdleCallback method if needed
   **/
  global.requestIdleCallback =
    global.requestIdleCallback ||
    function(cb) {
      const start = Date.now()
      return setTimeout(function() {
        cb({
          didTimeout: false,
          timeRemaining: function() {
            return Math.max(0, 50 - (Date.now() - start))
          },
        })
      }, 1)
    }

  global.cancelIdleCallback =
    global.cancelIdleCallback ||
    function(id) {
      clearTimeout(id)
    }

  if (!String.prototype.startsWith) {
    // $FlowFixMe - Allow this
    String.prototype.startsWith = function(search, pos) {
      return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search
    }
  }

  if (!Object.entries) {
    // $FlowFixMe - Allow this
    Object.entries = function(obj) {
      var ownProps = Object.keys(obj)
      var i = ownProps.length
      var resArray = new Array(i)
      while (i--) {
        resArray[i] = [ownProps[i], obj[ownProps[i]]]
      }
      return resArray
    }
  }
}

export default loadPolyfills
