// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQuery } from '../../styles/media'
import Grid from '../Grid/Grid'
import type { AreaTypes } from '../../types/enums/AreaTypes'
import { AreaConsumer } from '../AreaContext/AreaContext'

type Props = {
  children?: React.Node,
  clearChildMargin?: boolean,
  hasPadding?: boolean,
  outerMargin?: number,
  marginTop?: number,
  paddingTop?: number,
  marginBottom?: boolean,
  xsMargin?: number,
  smMargin?: number,
  mdMargin?: number,
  lgMargin?: number,
  hdMargin?: number,
  noGrid?: boolean,
  className?: string,
  area?: AreaTypes,
  roundedTop?: boolean,
  roundedBottom?: boolean,
}

type State = {}

const Section = styled.section`
  position: relative;
  margin-top: ${({ marginTop }) => (marginTop ? rem(marginTop) : undefined)};
  padding-top: ${({ paddingTop }) =>
    paddingTop ? rem(paddingTop) : undefined};
  margin-bottom: ${p => (p.marginBottom ? '2.5rem' : undefined)};
  ${p =>
    p.marginBottom &&
    mediaQuery.sm`
    margin-bottom: 3.125rem;
  `};

  ${({ area }) =>
    area === 'library' &&
    `background-color: #fff;  
  `}

  ${({ roundedTop }) => roundedTop && `border-radius: 8px 8px 0 0; `}

  ${({ roundedBottom }) =>
    roundedBottom &&
    `border-radius: 0 0 8px 8px;
  `}
`

const MARGINS = {
  default: {
    outerMargin: 2,
    smMargin: 3,
    mdMargin: 6,
  },
  article: {
    outerMargin: 3,
    smMargin: 10,
    mdMargin: 13,
    lgMargin: 16,
    hdMargin: 18,
  },
  library: {
    outerMargin: 3.2,
    smMargin: 10,
    mdMargin: 13,
    lgMargin: 16,
  },
}

class Ribbon extends React.PureComponent<Props, State> {
  static displayName = 'Ribbon'
  static defaultProps = {
    noGrid: false,
    outerMargin: 2,
    smMargin: 3,
    mdMargin: 6,
    marginBottom: true,
    area: 'wide',
  }

  static getMargins(props: Props, area: AreaTypes) {
    if (area === 'article' || area === 'library') {
      return MARGINS[area]
    }

    return {
      outerMargin:
        props.outerMargin !== undefined
          ? props.outerMargin
          : MARGINS.default.outerMargin,
      xsMargin: props.xsMargin,
      smMargin:
        props.smMargin !== undefined
          ? props.smMargin
          : MARGINS.default.smMargin,
      mdMargin:
        props.mdMargin !== undefined
          ? props.mdMargin
          : MARGINS.default.mdMargin,
      lgMargin: props.lgMargin,
      hdMargin: props.hdMargin,
    }
  }

  render() {
    const {
      children,
      clearChildMargin,
      marginBottom,
      marginTop,
      paddingTop,
      hasPadding,
      noGrid,
      className,
      roundedBottom,
      roundedTop,
    } = this.props

    return (
      <AreaConsumer>
        {area => {
          const margins = Ribbon.getMargins(this.props, area)

          return (
            <Section
              hasPadding={hasPadding}
              marginBottom={marginBottom}
              area={area}
              marginTop={marginTop}
              paddingTop={paddingTop}
              className={className}
              roundedBottom={roundedBottom}
              roundedTop={roundedTop}
            >
              {!noGrid ? (
                <Grid clearChildMargin={clearChildMargin} {...margins}>
                  {children}
                </Grid>
              ) : (
                children
              )}
            </Section>
          )
        }}
      </AreaConsumer>
    )
  }
}

export default Ribbon
