// @flow
import * as React from 'react'
import styled, { css } from 'styled-components'
import { mediaOnly } from '../../styles/media'
import { rem } from 'polished'
import {
  responsiveFont,
  getLineHeight,
  getFontSize,
} from '../../styles/style-helpers'

type Props = {
  /** The visual size of the Heading */
  level: 1 | 2 | 3 | 4 | 5,
  /** The actual HTML element that should be rendered in the browser */
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span' | 'p',
  /** If children is an array of nodes, they will be separated by <br/> elements */
  children?: React.Node,
  /** Don't split the children into multiple headings */
  noBreaks?: boolean,
  marginBottom?: 'xlarge' | 'large' | 'normal' | 'small' | 'none',
  responsiveSize?: boolean,
  invert?: boolean,
  themed?: boolean,
  theme?: string,
}

/************* THEMED MIXINS ****************/

const h1Mixin = {
  main: css`
    font-size: ${({ responsiveSize }) =>
      responsiveSize ? responsiveFont(28, 44) : rem(44)};
  `,
  kids: css`
    font-size: ${({ responsiveSize }) =>
      responsiveSize ? responsiveFont(28, 48) : rem(48)};
  `,
  lgbt: css`
    font-size: ${({ responsiveSize }) =>
      responsiveSize ? responsiveFont(28, 48) : rem(48)};
  `,
}

const h2Mixin = {
  main: css`
    font-size: ${({ responsiveSize }) =>
      responsiveSize ? responsiveFont(24, 24) : rem(24)};
  `,
  kids: css`
    font-size: ${({ responsiveSize }) =>
      responsiveSize ? responsiveFont(24, 28) : rem(28)};
  `,
  lgbt: css`
    font-size: ${({ responsiveSize }) =>
      responsiveSize ? responsiveFont(24, 28) : rem(28)};
  `,
}

const h3Mixin = {
  main: css`
    font-size: ${({ responsiveSize }) =>
      responsiveSize ? responsiveFont(20, 20) : rem(20)};
  `,
  kids: css`
    font-size: ${({ responsiveSize }) =>
      responsiveSize ? responsiveFont(20, 24) : rem(24)};
  `,
  lgbt: css`
    font-size: ${({ responsiveSize }) =>
      responsiveSize ? responsiveFont(20, 24) : rem(24)};
  `,
}

const h4Mixin = {
  main: css`
    font-size: ${({ responsiveSize }) =>
      responsiveSize ? responsiveFont(16, 16) : rem(16)};
  `,
  kids: css`
    font-size: ${({ responsiveSize }) =>
      responsiveSize ? responsiveFont(18, 20) : rem(20)};
  `,
  lgbt: css`
    font-size: ${({ responsiveSize }) =>
      responsiveSize ? responsiveFont(18, 20) : rem(20)};
  `,
}

/************* THEMED MIXINS END ****************/

const baseStyle = css`
  font-family: ${({ theme }) => theme.fontFamily};
  line-height: ${getLineHeight('tiny')};
  font-weight: 900;
  margin: 0 0
    ${p => {
      if (p.marginBottom === 'small') return rem(4)
      if (p.marginBottom === 'normal') return rem(16)
      if (p.marginBottom === 'large') return rem(24)
      if (p.marginBottom === 'xlarge') return rem(40)
      if (p.marginBottom === 'none') return '0'
    }};
  word-wrap: break-word;

  color: ${p => (p.invert ? p.theme.textInvert : p.theme.primary)};

  /* Ignore invert prop and use theme color if themed = true */
  ${({ themed, theme }) =>
    themed && theme.accent ? `color: ${theme.accent}` : null};
  ${mediaOnly.xs`
    margin-bottom: ${p => {
      if (p.marginBottom === 'xlarge') return rem(30)
      return undefined
    }};
  `};
`

const H1 = styled.h1`
  ${baseStyle};
  ${({ theme }) => h1Mixin[theme.name]};

  margin-bottom: ${p => {
    if (p.marginBottom === 'normal') return rem(24)
    if (p.marginBottom === 'large') return rem(40)
    if (p.marginBottom === 'xlarge') return rem(64)
    if (p.marginBottom === 'none') return '0'
  }};

  ${mediaOnly.xs`
    font-size: ${rem(28)}
    margin-bottom: ${p => {
      if (p.marginBottom === 'normal') return rem(24)
      if (p.marginBottom === 'large') return rem(30)
      if (p.marginBottom === 'xlarge') return rem(48)
      if (p.marginBottom === 'none') return '0'
    }};
  `};
`

const H2 = styled.h2`
  ${baseStyle};
  ${({ theme }) => h2Mixin[theme.name]};
`

const H3 = styled.h3`
  line-height: ${getLineHeight('small')};
  ${baseStyle};
  ${({ theme }) => h3Mixin[theme.name]};
`

const H4 = styled.h4`
  line-height: ${getLineHeight('small')};
  ${baseStyle};
  ${({ theme }) => h4Mixin[theme.name]};
`

const H5 = styled.h5`
  line-height: ${getLineHeight('small')};
  ${baseStyle};
  font-size: ${getFontSize('tiny')};
  font-weight: 700;
`

// Add the styles to array for easy lookup
const sizes = [H1, H2, H3, H4, H5]

/**
 * Render a text heading
 */
export default class Heading extends React.PureComponent<Props> {
  static defaultProps = {
    level: 2,
    marginBottom: 'normal',
  }

  render() {
    const { level, tag, children, marginBottom, noBreaks, ...rest } = this.props
    const Styled = sizes[level - 1]
    const Tag = tag ? Styled.withComponent(tag) : Styled
    const activeTheme = this.props.theme

    return (
      <Tag marginBottom={marginBottom} theme={activeTheme} {...rest}>
        {!noBreaks
          ? React.Children.map(children, (child, index) =>
              index > 0 ? [<br key="br" />, child] : child,
            )
          : children}
      </Tag>
    )
  }
}
