// @flow

import ScrollIntoView from 'scroll-into-view'

export default (
  id: string,
  alignOptions: Object,
  cb?: (bool: boolean) => void,
) => {
  if (!id) return false
  const node = document.getElementById(id)
  if (node) {
    ScrollIntoView(
      node,
      {
        time: 400,
        align: {
          top: 0,
          ...alignOptions,
        },
      },
      (type: 'complete' | 'canceled') => {
        if (cb) cb(type === 'complete')
      },
    )
    return true
  } else if (cb) {
    cb(false)
  }

  return false
}
