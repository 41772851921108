// @flow
import * as React from 'react'

type Props = {
  hoverStyle: Object,
}

type State = {
  hover: boolean,
}

// This function takes a component...
function withHover<Config: Props>(
  WrappedComponent: React.AbstractComponent<Config>,
): React.AbstractComponent<Config> {
  return class extends React.Component<Object, State> {
    constructor(props) {
      super(props)

      this.state = {
        hover: false,
      }
    }

    handleMouseEnter = () => {
      this.setState({
        hover: true,
      })
    }

    handleMouseLeave = () => {
      this.setState({
        hover: false,
      })
    }

    render() {
      const { hoverStyle, style, ...rest } = this.props
      const { hover } = this.state
      const { handleMouseEnter, handleMouseLeave } = this

      const styling = { ...(hover ? hoverStyle || {} : {}), ...(style || {}) }

      return (
        <WrappedComponent
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={styling}
          {...rest}
        />
      )
    }
  }
}

export default withHover
