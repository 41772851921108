// @flow
import * as React from 'react'
import styled, { css } from 'styled-components'
import config, { createQuery } from './grid-config'
import { clearMargin } from '../../styles/style-helpers'
import type { DimensionNameTypes } from '../../types/enums/DimensionNameTypes'

type Props = {
  flex?: boolean,
  tag?: string,
  clearChildMargin?: boolean,
  children?: React.Node,
  outerMargin?: number,
  xsMargin?: number,
  smMargin?: number,
  mdMargin?: number,
  lgMargin?: number,
  hdMargin?: number,
  width?: DimensionNameTypes,
}

const outerMargin = (key: DimensionNameTypes | boolean, value?: number) => {
  if (typeof value === 'number') {
    return createQuery(
      key,
      css`
        padding-left: ${value * 10}px;
        padding-right: ${value * 10}px;
      `,
    )
  }
  return null
}
/**
 * Use <Grid> to contain and center items.
 * You can apply an outer margin (actually padding in CSS), to create spacing to the edge.
 * The margin value is multiplied by the scale of 10,
 */
const StyledGrid = styled.div`
  margin: 0 auto;
  max-width: ${p => config.container[p.width] || config.container.hd}em;
  ${(p: Props) => p.flex && 'display: flex'};
  ${(p: Props) => clearMargin(p.clearChildMargin)};
  ${(p: Props) => outerMargin(true, p.outerMargin)}
  ${(p: Props) => outerMargin('xs', p.xsMargin)}
  ${(p: Props) => outerMargin('sm', p.smMargin)}
  ${(p: Props) => outerMargin('md', p.mdMargin)}
  ${(p: Props) => outerMargin('lg', p.lgMargin)}
  ${(p: Props) => outerMargin('hd', p.hdMargin)}
`

class Grid extends React.PureComponent<Props> {
  static displayName = 'Grid'

  render() {
    const { tag, ...rest } = this.props
    const GridEl = tag ? StyledGrid.withComponent(tag) : StyledGrid

    return <GridEl {...rest} />
  }
}

export default Grid
