// @flow
import type { DimensionNameTypes } from '../types/enums/DimensionNameTypes'

export const DIMENSION_NAMES = ['xs', 'sm', 'md', 'lg', 'hd']
import { css } from 'styled-components'

type MediaQuery = {
  xs: Function,
  sm: Function,
  md: Function,
  lg: Function,
  hd: Function,
}

const breakpoints = {
  xs: 0,
  sm: 48, // 48*16 = 768px
  md: 64, // 64*16 = 1024px
  lg: 75, // 75*16 = 1200px
  hd: 88, // 88*16 = 1408px
}

const pxBreakpoints = {
  sm: breakpoints.sm * 16,
  md: breakpoints.md * 16,
  lg: breakpoints.lg * 16,
  hd: breakpoints.hd * 16,
}

function makeMedia(media: string) {
  return (...args: any) => {
    return css`
      @media ${media} {
        ${css(...args)};
      }
    `
  }
}

// Generate media queries that target the query and up
const mediaQuery: MediaQuery = DIMENSION_NAMES.reduce((acc, breakpoint) => {
  const breakpointWidth = breakpoints[breakpoint]
  acc[breakpoint] = makeMedia(
    ['only screen', breakpoint !== 0 && `(min-width: ${breakpointWidth}em)`]
      .filter(Boolean)
      .join(' and '),
  )
  return acc
}, {})

// Generate media queries that only targets the specific query
const mediaOnly: MediaQuery = DIMENSION_NAMES.reduce(
  (acc, breakpoint, index) => {
    const breakpointWidth = breakpoints[breakpoint]
    const nextBreakpoint: ?DimensionNameTypes =
      index < DIMENSION_NAMES.length ? DIMENSION_NAMES[index + 1] : null

    acc[breakpoint] = makeMedia(
      [
        'only screen',
        breakpoint !== 0 && `(min-width: ${breakpointWidth}em)`,
        nextBreakpoint &&
          `(max-width: ${(breakpoints[nextBreakpoint] * 16 - 1) / 16}em)`,
      ]
        .filter(Boolean)
        .join(' and '),
    )
    return acc
  },
  {},
)

export { mediaQuery, mediaOnly, breakpoints, pxBreakpoints }
