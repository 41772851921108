// @flow
import * as React from 'react'

export const RouteContext: React.Context<any> = React.createContext({
  location: {
    pathname: '/',
  },
})
export const { Provider, Consumer } = RouteContext

export type Location = {
  pathname: string,
  hash: string,
  search: string,
  state: string,
  key: string,
}
export type History = {
  location: Location,
  replace: Function,
  push: Function,
  listen: (cb: Function) => () => void,
}

type Props = {
  location?: string,
  children?: React.Node,
}

type State = {
  history: History,
}

/***
 * Router exposes the history instance, allowing you to consume it with the Context API.
 * We are not using `react-router`, since we don't need the actual routing functionality - just the history.
 *
 * ```
 * <RouteContext.Consumer>
 *   {history => (
 *    <Component history={history} />
 *   )}
 * </RouteContext.Consumer>
 * ```
 */
class Router extends React.Component<Props, State> {
  static displayName = 'Router'
  static defaultProps = {}

  listener = undefined
  /* eslint-disable react/sort-comp */
  history: History
  /* eslint-enable react/sort-comp */

  constructor(props: Props) {
    super(props)

    if (process.env.SERVER) {
      const createHistory = require('history').createMemoryHistory
      // Make sure to create history
      this.history = createHistory({
        initialEntries: props.location ? [props.location] : undefined,
      })
      this.state = {
        history: this.history,
      }
    } else {
      const createHistory = require('history').createBrowserHistory
      this.history = createHistory()
      this.state = {
        history: this.history,
      }
    }
  }

  componentDidMount() {
    this.listener = this.history.listen(this.historyChanged)
  }

  componentWillUnmount() {
    if (this.listener) {
      this.listener()
    }
    this.listener = undefined
  }

  historyChanged = (location: Location, action: string) => {
    this.setState({
      // Recreate the history, so the Context is updated
      // Only sets a subset of the methods available. More can be added if needed.
      history: {
        push: this.history.push,
        replace: this.history.replace,
        listen: this.history.listen,
        location,
      },
    })
  }

  render() {
    return <Provider value={this.state.history}>{this.props.children}</Provider>
  }
}

// Create a HOC to wrap with the context
export function withRouter(Component: React.ComponentType<any>) {
  // ...and returns another component...
  function RouteComponent(props: Object) {
    // ... and renders the wrapped component with the context route!
    return (
      <Consumer>
        {history => (
          <Component {...props} location={history.location} history={history} />
        )}
      </Consumer>
    )
  }
  RouteComponent.displayName = 'withRouter'

  return RouteComponent
}

export default Router
