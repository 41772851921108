// @flow
import * as React from 'react'
import withHover from '../hoc/withHover'

const InfoIcon = ({ style, invert = false, ...rest }: Object) => (
  <svg
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    stroke="currentColor"
    style={{ cursor: 'pointer', ...style }}
    {...rest}
  >
    <g
      fill={invert ? 'white' : 'currentColor'}
      stroke={invert ? 'white' : 'currentColor'}
      strokeLinecap="round"
      strokeWidth="2"
    >
      <circle
        cx="12"
        cy="12"
        fill={invert ? 'currentColor' : 'none'}
        r="11"
        stroke={'currentColor'}
      />
      <line fill="none" x1="11.959" x2="11.959" y1="11" y2="17" />
      <circle cx="11.959" cy="7" r="1" stroke="none" />
    </g>
  </svg>
)

export default withHover<React.Config<Object, {}>>(InfoIcon)
