// @flow
import * as React from 'react'
import styled from 'styled-components'

import type { LinkViewModel } from '../../types/LinkViewModel'

import { focusOutline } from '../../styles/style-helpers'
import Link from '../Link'

export type ButtonState = {
  hover?: boolean,
  touch?: boolean,
  focus?: boolean,
  active?: boolean,
  /** Any of focus, touch or hover is true */
  hovering?: boolean,
}

type Props = LinkViewModel & {
  buttonType?: string,
  children?: React.Node | ((state: ButtonState) => React.Node),
  /** Always render as anchor tag, don't fall back to button */
  isAnchor?: boolean,
  disabled?: boolean,
  /** Get the current interaction state of the button */
  onInteraction?: Function,
}

/**
 * Basic button styles
 **/
const Button = styled.button`
  padding: 0;
  display: block;
  position: relative;
  border: none;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  font-family: ${({ theme }) => theme.fontFamily};
  text-align: left;
  ${focusOutline()};
  outline-offset: 4px;

  &[disabled] {
    cursor: default;
  }
`

const Anchor = Button.withComponent(Link)

class BaseButton extends React.Component<Props, ButtonState> {
  static displayName = 'BaseButton'
  state = {
    hover: false,
    touch: false,
    focus: false,
    active: false,
    hovering: false,
  }

  /* eslint-disable react/sort-comp */
  handleEvent = (e: Event) => {
    const { onInteraction } = this.props
    const type = e.type

    this.setState(
      state => {
        /* Queue the state change, so we keep the correct event flow */
        const newState: ButtonState = { ...state }
        switch (type) {
          case 'mouseenter':
            if (!state.touch) {
              newState.hover = true
            }
            break
          case 'touchend':
          case 'touchcancel':
          case 'mouseleave':
            newState.hover = false
            newState.active = false
            newState.touch = false
            break
          case 'mousedown':
            newState.active = true
            break
          case 'mouseup':
            newState.active = false
            break
          case 'focus':
            newState.focus = true
            break
          case 'blur':
            newState.focus = false
            break
          case 'touchstart':
            newState.touch = true
            break
          default:
            return
        }

        newState.hovering = newState.touch || newState.focus || newState.hover
        return newState
      },
      onInteraction
        ? () => {
            onInteraction(this.state)
          }
        : undefined,
    )
  }

  // handleEvent must be defined first!
  events = {
    onMouseEnter: this.handleEvent,
    onMouseLeave: this.handleEvent,
    onMouseDown: this.handleEvent,
    onMouseUp: this.handleEvent,
    onTouchStart: this.handleEvent,
    onTouchEnd: this.handleEvent,
    onTouchCancel: this.handleEvent,
    onFocus: this.handleEvent,
    onBlur: this.handleEvent,
  }

  render() {
    const {
      href,
      disabled,
      isAnchor,
      children,
      onInteraction,
      buttonType,
      ...rest
    } = this.props
    const renderAsAnchor = isAnchor || (!!href && !disabled)
    const Comp = renderAsAnchor ? Anchor : Button
    const events =
      !!onInteraction || typeof children === 'function' ? this.events : null

    const inner =
      typeof children === 'function' ? children(this.state) : children

    if (renderAsAnchor) {
      return (
        <Comp href={href} {...events} {...rest}>
          {inner}
        </Comp>
      )
    } else {
      return (
        <Comp
          type={buttonType || 'button'}
          disabled={disabled || undefined}
          {...events}
          {...rest}
        >
          {inner}
        </Comp>
      )
    }
  }
}

export default BaseButton
