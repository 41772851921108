// @flow
import * as React from 'react'
import image from './previewModeBadge.png'
import type { LinkViewModel } from '../../types/LinkViewModel'
import styled from 'styled-components'

type Props = {
  currentUrl: string,
  link: LinkViewModel,
}

type State = {}

const Badge = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  width: 120px;
  height: 120px;
  background: url(${image}) no-repeat;
  background-size: 120px 120px;
  z-index: 3;
`

class PreviewBadge extends React.Component<Props, State> {
  static displayName = 'PreviewBadge'
  static defaultProps = {}

  render() {
    const { link, currentUrl } = this.props
    if (!link || !link.href) return null

    return (
      <Badge
        title={link.label}
        href={`${link.href}?redir=${currentUrl || ''}`}
      />
    )
  }
}

export default PreviewBadge
