// @flow
/* eslint-disable global-require, no-underscore-dangle */
// It's important that we import 'core-js' before everything else, including React.
// Importing it later will break React in IE11
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'react-intersection-observer'
import 'focus-visible'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { preloadModules } from './modules/modules-utils'
import App from './App/App'
import loadPolyfills from './utils/polyfills'
import Router from './App/Router'
import ErrorBoundary from './App/ErrorBoundary'
import { initSentry } from './utils/error-tracking'

import type { HtmlViewModel } from './types/HtmlViewModel'
import type { HtmlConfigViewModel } from './types/HtmlConfigViewModel'
import type { ModuleInstance } from './modules/modules-utils'

/**
 * Initialize the client code.
 */
export default function init() {
  const data: HtmlViewModel = global.__INITIAL

  if (process.env.NODE_ENV === 'production') {
    initSentry('https://a759326bb5484745950757e5189ee2e7@sentry.io/1394077')
  }

  if (module.hot) {
    // Cache the data for hot reloading
    global.__HOT_PAGE_DATA = data
  }

  // Preload polyfills and modules before rendering the app
  return Promise.all([preloadModules(data.modules), loadPolyfills()]).then(
    ([moduleInstances]) => {
      renderApp(moduleInstances, data)
    },
  )
}

function renderApp(modules: Array<ModuleInstance>, { meta }: HtmlViewModel) {
  const root = document.getElementById('root')

  if (root) {
    // Fetch the initial config, so we can instantiate the site
    const config: HtmlConfigViewModel = window.__CONFIG || {}

    const app = (
      <ErrorBoundary module="App" debug={config.debug}>
        <Router>
          <App initialModules={modules} initialMeta={meta} config={config} />
        </Router>
      </ErrorBoundary>
    )

    // If developing over the proxy, use render to overwrite to server output since it won't match
    if (!!process.env.PROXY || module.hot) {
      ReactDOM.render(app, root)
    } else {
      ReactDOM.hydrate(app, root)
    }
  }
}

if (module.hot) {
  // If modules are updated, we need to preload the new modules before rendering
  module.hot.accept('./modules/modules-utils', () => {
    // Fetch the current modules, and reinit the app
    const data: HtmlViewModel = global.__HOT_PAGE_DATA
    preloadModules(data.modules).then(modules => {
      renderApp(modules, data)
    })
  })
}

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') {
  // Init the client now - Moved inside init function to make it testable :)
  if (!global.VERSION) {
    global.VERSION = process.env.VERSION

    init()
    if (process.env.NODE_ENV === 'production') {
      console.log(
        `%cFamilieretshuset`,
        'font-weight: 700; font-size:20px; color: #14143C',
      )
      console.log(`%c${global.VERSION}`, 'font-weight: 700; color: #FF6C0C')
    }
  }
}
