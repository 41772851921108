// @flow
import type { ModuleViewModel } from '../types/ModuleViewModel'
import { getModule } from './modules-map'
import type { AreaTypes } from '../types/enums/AreaTypes'

export type ModuleInstance = {
  module: Function,
  name: string,
  props: Object & {
    area?: AreaTypes,
  },
}

export type SplitModules = {
  header?: ModuleInstance,
  cookieBanner?: ModuleInstance,
  cookieDisclaimerModule?: ModuleInstance,
  hero?: ModuleInstance,
  breadcrumbs?: ModuleInstance,
  printButton?: ModuleInstance,
  date?: ModuleInstance,
  wizard?: ModuleInstance,
  footer?: ModuleInstance,
  preModules: Array<ModuleInstance>,
  article: Array<ModuleInstance>,
  sidebar: Array<ModuleInstance>,
  library: Array<ModuleInstance>,
  narrow: Array<ModuleInstance>,
  modules: Array<ModuleInstance>,
}

/**
 * When rendering serverside, fetch the module instance synchronously
 */
export function staticModules(
  modules: Array<ModuleViewModel>,
): Array<ModuleInstance> {
  return modules.reduce((acc, { name, properties }) => {
    const mod = getModule(name)
    if (mod) {
      acc.push({
        module: mod.default,
        name,
        props: properties,
      })
    } else {
      console.error(`Tried to render unknown React module: "${name}"`)
    }
    return acc
  }, [])
}

/**
 * When rendering on the client, preload module instance before rendering.
 * This ensures they can render when React is trying to render them
 */
export function preloadModules(modules: Array<ModuleViewModel>) {
  const promises = modules.reduce((acc, { name, properties }) => {
    const mod = getModule(name)
    if (mod) {
      const promise = mod.default().then(module => ({
        module: module.default,
        name,
        props: properties,
      }))
      acc.push(promise)
    } else {
      console.error(`Tried to preload unknown React module: "${name}"`)
    }
    return acc
  }, [])

  return Promise.all(promises)
}

/**
 * Split root modules from content modules
 **/
export function splitModules(modules: Array<ModuleInstance>): SplitModules {
  return modules.reduce(
    (acc, module) => {
      switch (module.name) {
        case 'Header':
          acc.header = module
          break
        case 'Footer':
          acc.footer = module
          break
        case 'CookieBanner':
          acc.cookieBanner = module
          break
        case 'CookieDisclaimerModule':
          acc.cookieDisclaimerModule = module
          break
        case 'Wizard':
          acc.wizard = module
          break
        case 'Breadcrumbs':
          acc.breadcrumbs = module
          break
        case 'DisplayDate':
          acc.date = module
          break
        case 'PrintButton':
          acc.printButton = module
          break
        default:
          // Split modules on area, so we can render in relevant layout
          if (module.props.area === 'article') acc.article.push(module)
          else if (module.props.area === 'library') acc.library.push(module)
          else if (module.props.area === 'narrow') acc.narrow.push(module)
          else if (module.props.area === 'sidebar') acc.sidebar.push(module)
          else if (acc.article.length && module.props.area !== 'none')
            acc.modules.push(module)
          else if (module.props.area !== 'none') acc.preModules.push(module)
          break
      }
      return acc
    },
    {
      preModules: [],
      article: [],
      sidebar: [],
      library: [],
      narrow: [],
      modules: [],
      header: undefined,
      footer: undefined,
      cookieBanner: undefined,
      cookieDisclaimerModule: undefined,
      wizard: undefined,
      date: undefined,
      breadcrumbs: undefined,
      printButton: undefined,
    },
  )
}

/**
 * Flatten and return all unique module names
 **/
export function flatModulesList(
  modules: Array<ModuleViewModel> = [],
): Array<string> {
  return [...new Set(modules.map(module => module.name))]
}
