// @flow
import * as React from 'react'

const RemoveIcon = ({ style, color = 'currentColor', ...rest }: Object) => (
  <svg
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    style={{ cursor: 'pointer', ...style }}
    {...rest}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      stroke={color}
      fill="none"
      strokeMiterlimit={10}
    >
      <path d="M19 5L5 19M19 19L5 5" />
    </g>
  </svg>
)

export default RemoveIcon
