// @flow
import React, { useReducer } from 'react'

import type { Node } from 'react'

const ACTIONS = {
  ADD: 0,
  REMOVE: 1,
}

type Props = {
  children?: Node,
}

type Notification = {
  id: number,
  message: string,
  timeout?: number,
  actions?: Array<Object>,
}

type Context = {
  notifications: Array<Notification>,
  addNotification: Function,
  removeNotification: Function,
}

export const NotificationsContext = React.createContext<Context>({
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
})

function notificationsReducer(state, action) {
  const { notifications } = state

  switch (action.type) {
    case ACTIONS.ADD:
      const { notification } = action
      return {
        ...state,
        notifications: [...notifications, notification],
      }

    case ACTIONS.REMOVE:
      const { id } = action

      return {
        ...state,
        notifications: [...notifications].filter(item => id !== item.id),
      }

    default:
      return state
  }
}

let uniqId = 0
const generateId = () => uniqId++

function Notifications({ children }: Props) {
  const [state, dispatch] = useReducer(notificationsReducer, {
    notifications: [],
  })
  const { notifications } = state

  const addNotification = notification => {
    const newId = generateId()
    dispatch({
      type: ACTIONS.ADD,
      notification: {
        ...notification,
        id: newId,
      },
    })

    // close after timeout
    if (notification.timeout) {
      setTimeout(() => {
        dispatch({ type: ACTIONS.REMOVE, id: newId })
      }, notification.timeout)
    }
  }

  const removeNotification = id => {
    dispatch({
      type: ACTIONS.REMOVE,
      id,
    })
  }

  return (
    <NotificationsContext.Provider
      value={{ notifications, addNotification, removeNotification }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default Notifications
