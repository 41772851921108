// @flow
const modulesMap = new Map()

function createModules() {
  if (process.env.SERVER) {
    /* When rendering on the Server we use the index.js to load the static module */
    const modules = require.context('./', true, /index\.js$/)

    modules.keys().forEach(name => {
      const moduleName = name.replace(/\.\/(\w+?)\/index.js$/, '$1')
      modulesMap.set(moduleName, modules(name))
    })
  } else {
    /* When rendering on the Client we use the importer.js to dynamically load the module.
     * Check if it exists, to let the client know that this is a module that be loaded */
    const modules = require.context('./', true, /importer\.js$/)
    modules.keys().forEach(name => {
      const moduleName = name.replace(/\.\/(\w+?)\/importer.js$/, '$1')
      modulesMap.set(moduleName, modules(name))
    })
  }
}

export function getModule(name: string) {
  if (!name) return null
  if (process.env.NODE_ENV === 'test') {
    // eslint-disable-next-line
    if (process.env.SERVER) {
      return require(`./${name}/index.js`)
    } else {
      return require(`./${name}/importer.js`)
    }
  }
  if (!modulesMap.size) createModules()
  return modulesMap.get(name)
}

if (module.hot) {
  // If this module is reloaded, it should dispose the modules maps so it can be refreshed on next render
  module.hot.dispose(() => {
    modulesMap.clear()
  })
}
