// @flow
import * as React from 'react'
import type { ModuleInstance } from '../modules/modules-utils'

type Props = {
  modules: Array<ModuleInstance>,
}

type State = {}

/**
 * When the page is changed, we reset the focus to the top of the page using the <PageFocus> component
 * */
class PageFocus extends React.PureComponent<Props, State> {
  static displayName = 'PageFocus'

  focusRef: { current: null | HTMLDivElement } = React.createRef()

  componentDidUpdate(prevProps: Props) {
    if (this.props.modules !== prevProps.modules && this.focusRef.current) {
      this.focusRef.current.focus()
    }
  }

  render() {
    return <div data-page-focus tabIndex={-1} ref={this.focusRef} />
  }
}

export default PageFocus
