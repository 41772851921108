// @flow

export const durations = {
  slow: '0.5s',
  medium: '0.3s',
  fast: '0.15s',
}

/**
 * React spring preset
 * */
export const presets = {
  fast: { tension: 170, friction: 16, clamp: true },
  stiff: { stiffness: 200, friction: 23, clamp: true },
}
