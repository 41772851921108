// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import { mediaQuery } from '../../styles/media'
import { AreaProvider } from '../AreaContext/AreaContext'

type Props = {
  children?: React.Node,
}

const StickyEl = styled.aside`
  /* @TODO - Get header height with JS */
  position: sticky;
  top: ${rem(176)};
  align-self: flex-start;
  height: auto;
  margin: 0 auto;

  ${mediaQuery.md`
    margin: 0 0;
  `};

  @media print {
    display: none;
  }
`

const Sidebar = ({ children, ...rest }: Props) => (
  <AreaProvider value="sidebar">
    <StickyEl {...rest}>
      <div>{children}</div>
    </StickyEl>
  </AreaProvider>
)

export default Sidebar
