// @flow
import styled from 'styled-components'
import { mediaQuery } from '../../styles/media'

const WhiteEdge = styled.div`
  display: none;
  position: absolute;
  z-index: 20;
  background: white;
  height: 100%;
  width: 40px;
  top: 0;
  bottom: 0;

  ${mediaQuery.md`
    display: block;
  `}
`

export default WhiteEdge
