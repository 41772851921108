// @flow
import { fetchError } from './error-tracking'

const defaultGetHeaders = {}
const defaultPostHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {Response} response A response from a network request
 * @return {object}          The parsed JSON from the request
 */
export function parseJSON(response: Response): Promise<Object> {
  const contentType = response.headers.get('content-type')
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json().catch(error => {
      // If we fail to parse the JSON log it and return empty object
      fetchError(error, response)
      return {}
    })
  } else {
    return Promise.resolve({})
  }
}

function redirect(body) {
  if (body && typeof body.redirect === 'string') {
    // If the response has a redirect key, update the current browser URL
    global.location.assign(body.redirect)
  }

  return body
}

async function handleError(response: Response, request: RequestOptions) {
  const url = global.URL
    ? new global.URL(response.url)
    : { pathname: response.url }
  const body = await parseJSON(response)
  const error = new Error(body.message || response.statusText)

  switch (response.status) {
    case 400: // Bad Request - Form validation error or similar
    case 401: // Unauthorized
    case 403: // Forbidden
      // Expected errors, so don't log as error
      break
    default:
      fetchError(
        new Error(
          `${response.status}: ${response.statusText} (${url.pathname})`,
        ),
        response,
        request,
        { body },
      )
      break
  }

  // Perform a redirect if necessary
  redirect(body)

  return Promise.reject({
    message: body.message || error.message,
    error,
    response,
    request,
    body,
  })
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {Response} response   A response from a network request
 * @param  {RequestOptions} request   The original request options
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export function checkStatus(response: Response, request: RequestOptions) {
  if (response.ok) return response
  return handleError(response, request)
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {RequestOptions} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(
  url: string,
  options: RequestOptions = {},
): Promise<Object> {
  const request = {
    method: options.method || 'GET',
    /* By default, we set the Content-Type for POST requests */
    headers: options.method === 'POST' ? defaultPostHeaders : defaultGetHeaders,
    ...options,
  }

  return fetch(url, request)
    .then((response: Response) => checkStatus(response, request))
    .then(parseJSON)
    .then(redirect)
}
