// @flow
import * as React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { rem } from 'polished'

import { getFontSize, getLineHeight } from '../../styles/style-helpers'
import type { FontSizes } from '../../styles/fonts'
import { mediaOnly } from '../../styles/media'

type Props = {
  children?: React.Node,
  marginBottom?: boolean,
  truncate?: boolean,
  /** Don't split the children into multiple paragraphs */
  noBreaks?: boolean,
  /** Size corresponding to the body styles in the styleguide */
  size?: FontSizes,
  weight?: 300 | 400 | 500 | 700,
  /** Limit the width of the paragraph to a readable 550px*/
  maxWidth?: boolean,
  /** The HTML tag to output - Defaults to <p> */
  tag?: any, // Not sure if right approach
  color?: string,
  invert?: boolean,
}

const P = styled.p`
  font-size: ${p => getFontSize(p.size)};
  line-height: ${p => getLineHeight(p.size)};
  ${p =>
    p.size === 'large' &&
    mediaOnly.xs`
    font-size: 1rem
  `};
  text-align: left;
  margin-top: 0;
  margin-bottom: ${(props: Props) => (props.marginBottom ? '2rem' : 0)};
  font-weight: ${(props: Props) => props.weight || 300};
  max-width: ${(props: Props) => (props.maxWidth ? '680px' : undefined)};

  color: ${(props: Props) => (props.color ? props.color : 'currentColor')};

  ${({ theme, size }) =>
    (theme.name === 'kids' || theme.name === 'lgbt') &&
    size === 'normal' &&
    `
    font-size: ${rem(theme.bodyFontSize)};
    font-weight: ${theme.bodyFontWeight};
  `}
`

function Paragraph({ children, noBreaks, tag, ...rest }: Props) {
  const theme = React.useContext(ThemeContext)
  const Tag = tag && tag !== 'p' ? P.withComponent(tag) : P

  if (React.Children.count(children) > 1 && !noBreaks) {
    const last = React.Children.count(children) - 1
    return React.Children.map(children, (child, index) => (
      <Tag marginBottom={index < last} theme={theme} {...rest}>
        {child}
      </Tag>
    ))
  }
  return (
    <Tag theme={theme} {...rest}>
      {children}
    </Tag>
  )
}

Paragraph.displayName = 'Paragraph'
Paragraph.defaultProps = {
  size: 'normal',
}

export default Paragraph
