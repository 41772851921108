// @flow
import * as React from 'react'
import { logException } from '../utils/error-tracking'
import FriendlyError from '../components/FriendlyError/FriendlyError'

type Props = {
  module: string,
  children?: React.Node,
  serverError?: Error,
  debug?: boolean,
}

type State = {
  hasError: boolean,
  error: ?Error,
  errorInfo: ?Object,
}

class ErrorBoundary extends React.Component<Props, State> {
  static displayName = 'ErrorBoundary'
  static defaultProps = {
    module: 'Unknown',
    debug: false,
  }

  state = {
    hasError: false,
    error: null,
    errorInfo: null,
  }

  componentDidMount() {
    if (this.props.serverError && !this.state.hasError) {
      /* If the server threw an error, ensure it gets shown  */
      this.prepareServerError(this.props.serverError)
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { error, errorInfo } = this.state
    if (error && prevState.error !== error) {
      logException(error, {
        info: errorInfo,
        module: this.props.module,
        page: global.location ? global.location.pathname : '',
        serverSide: this.props.serverError && !errorInfo,
      })
    } else if (error) {
      // Try and recover from Error
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasError: false,
        error: null,
        errorInfo: null,
      })
    }
  }

  componentDidCatch(
    error: Error,
    info: {
      componentStack: string,
    },
  ) {
    this.setState({ hasError: true, error, errorInfo: info })
  }

  prepareServerError(serverError: Error) {
    if (serverError) {
      this.setState({
        // Only show server errors in debug mode
        hasError: this.props.debug,
        error: serverError,
      })
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.props.serverError && !this.props.debug) {
        // If the error already occurred on the server, and not in debug mode then don't render anything for this component
        return null
      }
      const child = React.Children.only(this.props.children)
      const props = child ? child.props : {}
      const { error, errorInfo } = this.state

      return (
        <FriendlyError
          name={this.props.module || 'Unknown'}
          message={this.props.debug && error ? error.message : undefined}
          debug={this.props.debug}
          handleReload={() => {
            this.setState({ hasError: false, error: undefined })
          }}
          details={[
            {
              name: 'Props',
              value: props,
            },
            { name: 'Stacktrace', value: error ? error.stack : null },
            {
              name: 'Info',
              value: errorInfo,
            },
          ]}
        />
      )
    }
    return this.props.children || null
  }
}

export default ErrorBoundary
