// @flow
import * as Sentry from '@sentry/browser'

/**
 * Init Raven Sentry tracking
 */
export function initSentry(url: string, options?: Object) {
  if (url) {
    try {
      Sentry.init({
        dsn: url,
        release: process.env.VERSION,
        ignoreErrors: [
          'Invariant Violation',
          'ResizeObserver loop limit exceeded',
          'document.getElementsByClassName.ToString is not a function',
        ],
        ...options,
      })
    } catch (e) {
      // Failed to install Sentry for some reason - invalid key?
      console.error(e.message)
    }
  }
}

export function fetchError(
  error: Error,
  response: Object = {},
  request: Object = {},
  extra: ?Object = null,
) {
  if (error && error.message) {
    const { type, status, statusText, url, headers } = response
    const options = {
      tags: {
        fetch: 'fetch',
        page: global.location ? global.location.pathname : '',
      },
      extra: {
        ...extra,
        page: global.location ? global.location.pathname : '',
        url,
        request: {
          ...request,
        },
        response: {
          type,
          status,
          statusText,
          headers: headers ? Array.from(headers) : undefined,
        },
      },
    }

    Sentry.captureMessage(error.message, options)
  }
}

export function logException(ex: Error, context: ?Object = null) {
  Sentry.captureException(ex, {
    extra: context,
  })
  console.error('Log exception')
}

export function logMessage(msg: string, context: ?Object = null) {
  Sentry.captureMessage(msg, {
    extra: context,
  })
  consoleLog(msg, context)
}

function consoleLog(...args) {
  /* istanbul ignore next if */
  // eslint-disable-next-line no-console
  if (global.window && window.console && console.log) {
    console.log(...args) // eslint-disable-line no-console
  }
}
