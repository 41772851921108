// @flow
import { preloadModules } from '../modules/modules-utils'
import { parseJSON } from '../utils/request'
import type { HtmlViewModel } from '../types/HtmlViewModel'
import type { MetaViewModel } from '../types/MetaViewModel'
import type { ModuleInstance } from '../modules/modules-utils'
import { logException, logMessage } from '../utils/error-tracking'

const TIMEOUT = 1000

export type PageResult = {
  pathname: string,
  meta: MetaViewModel,
  modules: Array<ModuleInstance>,
}

/**
 * Fetch a the headless version of page, allowing us to update the running application
 **/
export async function fetchHeadlessPage(
  pathname: string,
  headless?: string,
): Promise<?PageResult> {
  // If headless is not set, skip load and return null
  if (!headless) return null

  try {
    const url = `${pathname.replace(/\/$/, '')}/${headless}`
    // Try to fetch the route as headless JSON data.
    const result: HtmlViewModel = await Promise.race([
      // Remove trailing '/' before adding headless query
      fetch(url, {
        credentials: 'same-origin',
      }).then(parseJSON),
      new Promise((resolve, reject) =>
        setTimeout(() => {
          reject(new Error(`Request timed out while loading ${url}`))
        }, TIMEOUT),
      ),
    ])

    // If we got a valid JSON response with correct data, prepare to change the page to it.
    if (result && result.modules && result.meta) {
      // Preload the modules on the page, so all the scripts are ready
      const preloadedModules = await preloadModules(result.modules)

      if (module.hot) {
        // Cache the current page data, so when hot reloading the client can inject the correct initial state.
        // This only happens in dev
        global.__HOT_PAGE_DATA = result
      }

      // If the result has required fields, update the page.
      return {
        meta: result.meta,
        modules: preloadedModules,
        pathname,
      }
    } else {
      logMessage('Trying to navigate to invalid page result', { url, result })
      return null
    }
  } catch (e) {
    logException(e, {
      pathname,
    })
    return null
  }
}
